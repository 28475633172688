/* @font-face {
    font-family: 'Roboto';
    src: local('Roboto'), url(./_assests/fontRoboto/Roboto-Regular.ttf) format('woff');
  }
  
  @font-face {
      font-family: 'Roboto';
      src: local('Roboto'), url(./_assests/fontRoboto/Roboto-Bold.ttf) format('woff');
  }
  @font-face {
      font-family: 'Roboto';
      src: local('Roboto'), url(./_assests/fontRoboto/Roboto-Italic.ttf) format('woff');
  }
  @font-face {
      font-family: 'Roboto';
      src: local('Roboto'), url(./_assests/fontRoboto/Roboto-Medium.ttf) format('woff');
  } */


  /* For ttf format, you have to mention format('truetype'). For woff, format('woff')*/
  
body {
    margin: 0;
    background-color: var(--sp-background-color-body);
    color: var(--sp-secondary-color);
    font-family: 'Roboto', Arial, sans-serif;
    letter-spacing: 0.5px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 14px;
    padding: 0;

}

code {
    font-family: 'Roboto', Arial, sans-serif;
}
/* 
:root {
    --font-size-base: 16px;
    --font-size-h1: clamp(2rem, 5vw + 1rem, 3.5rem);
    --font-size-h2: clamp(1.75rem, 4vw + 1rem, 3rem);
    --font-size-h3: clamp(1.5rem, 3vw + 1rem, 2.5rem);
    --font-size-h4: clamp(1.25rem, 2.5vw + 1rem, 2rem);
    --font-size-h5: clamp(1.125rem, 2vw + 0.5rem, 1.75rem);
    --font-size-h6: clamp(1rem, 1.5vw + 0.5rem, 1.5rem);
    --font-weight-normal: 400;
    --font-weight-medium: 500;
    --font-weight-bold: 700;
    --color-primary: #333;
    --color-heading: #0044cc; 
    --color-link: #008000; 
    --color-body-bg: #ffffff;
    --color-body-text: #333333;
    --line-height-body: 1.6;
    --line-height-heading: 1.3;
    --spacing-base: .5rem;
    --spacing-xs: 4px;
    --spacing-sm: 8px;
    --spacing-md: 16px;
    --spacing-lg: 24px;
    --spacing-xl: 32px;
}
body {
    font-size: var(--font-size-base);
    font-weight: var(--font-weight-normal);
    line-height: var(--line-height-body);
    color: var(--color-body-text);
    background-color: var(--color-body-bg);
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    margin: 0;
    padding: 0;
    text-align: left;
    scroll-behavior: smooth;
    box-sizing: border-box;
}

h1, h2, h3, h4, h5, h6 {
    font-family: 'Georgia', 'Times New Roman', serif;
    color: var(--color-heading);
    line-height: var(--line-height-heading);
    margin-top: 1.2em;
    margin-bottom: 0.5em;
}

h1 { font-size: var(--font-size-h1); font-weight: var(--font-weight-bold); }
h2 { font-size: var(--font-size-h2); font-weight: var(--font-weight-bold); }
h3 { font-size: var(--font-size-h3); font-weight: var(--font-weight-bold); }
h4 { font-size: var(--font-size-h4); font-weight: var(--font-weight-medium); }
h5 { font-size: var(--font-size-h5); font-weight: var(--font-weight-medium); }
h6 { font-size: var(--font-size-h6); font-weight: var(--font-weight-medium); }

p, li {
    font-size: var(--font-size-base);
    line-height: var(--line-height-body);
    margin-bottom: var(--spacing-base);
}

ul, ol {
    margin-left: 20px;
    padding-left: 20px;
    list-style-position: inside;
    margin-bottom: var(--spacing-base);
    list-style: none;
}

blockquote {
    font-size: 18px;
    font-style: italic;
    color: var(--color-heading);
    margin: 0 0 var(--spacing-base) 0;
    padding-left: var(--spacing-base);
    border-left: 4px solid var(--color-heading);
}

code, pre {
    font-family: 'Courier New', Courier, monospace;
    font-size: 14px;
    background-color: #f4f4f4;
    padding: 2px 4px;
    border-radius: 3px;
}


a {
    color: var(--color-link);
    text-decoration: none;
    font-weight: var(--font-weight-medium);
    list-style: none;
}

a:hover, a:focus {
    text-decoration: underline;
}

a:focus, button:focus, input:focus {
    outline: 2px dashed var(--color-link);
    outline-offset: 2px;
}


h1, h2, h3 {
    text-transform: uppercase;
}

@media (max-width: 768px) {
    h1 {
        font-size: 32px;
    }
    h2 {
        font-size: 28px;
    }
    body {
        font-size: 16px;
    }
}

@media (min-width: 768px) and (max-width: 1024px) {
    h1 {
        font-size: 40px;
    }
    h2 {
        font-size: 32px;
    }
    body {
        font-size: 18px;
    }
}

@media (min-width: 1024px) {
    h1 {
        font-size: 48px;
    }
    h2 {
        font-size: 36px;
    }
    body {
        font-size: 18px;
    }
}  */

* {
    margin: 0;
    padding: 0;
    scroll-behavior: smooth;
    box-sizing: border-box;
}

:root {
    --sp-primary-color: #5900ff !important;
    --sp-secondary-color: #344563;
    --sp-primary-dark: #;
    --sp-background-color: #ffffff;
    --sp-background-color-body: #f1f3f6;
    --sp-logo-color: #2684FF;
    --sp-border-input: #DFE1E6;
    --sp-background-button: #0052cc;
    --sp-background-button-hover: rgba(0, 82, 204, 0.9);
    --sp-exam-button-color: #05c148;
    --sp-p-color: #929FB0;
    --sp-p-heading: #C0CBD9;
}

.bg-white {
    background-color: var(--sp-background-color);
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: bold;
    line-height: 1.3;
    margin-bottom: 1em;
    color: #333;
}

h1 {
    font-size: 2.5rem;
}

h2 {
    font-size: 2rem;
}

h3 {
    font-size: 1.75rem;
}

h4 {
    font-size: 1.5rem;
}

h5 {
    font-size: 1.25rem;
}

h6 {
    font-size: 1rem;
}

p {
    font-size: 1rem;
    line-height: 1.5;
    /* Provides better readability */
    margin-bottom: 1em;
}

ul {
    padding: 0;
    margin: 0;
}

.link-text {
    font-size: 1rem;
    /* 16px */
    color: var(--sp-background-button);
    text-decoration: none;
    cursor: pointer;
}

.link-text:hover {
    color: darkblue;
    text-decoration: underline;
}

li {
    font-size: 1rem;
    list-style: none;
}

::-webkit-scrollbar {
    width: 10px;
}

::-webkit-scrollbar-track {
    background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
    background: #c2c0c0;
}

::-webkit-scrollbar-thumb:hover {
    background: #b4b1b1
}

.text-color-blue {
    color: var(--sp-background-button);
}

.text-color-green {
    color: #68ca9e;
}

.text-color-red {
    color: #f08891;
}

.bg-color-header {
    background-color: var(--sp-primary-color);
}

.margin-header {
    margin-top: 10px;
}

.pt-75 {
    padding-top: .75rem !important;
}

.mt-10 {
    margin-top: 10px !important;
}

.mt-20 {
    margin-top: 20px;
}

.pb-40 {
    padding-bottom: 40px;
}

.margin-page-bottom {
    margin-bottom: 20px;
}

.soft {
    opacity: 0.15;
}

.star-red {
    color: red;
}

.overlay {
    position: fixed;
    /* Positioning and size */
    left: 0;
    width: 100vw;
    height: calc(100vh - 56px);
    background-color: rgba(128, 128, 128, 0.5);
    z-index: 999;
    /* color */
    /* making it hidden by default */
}

.overlay.inactive {
    position: fixed;
    /* Positioning and size */
    left: 0;
    width: 100vw;
    height: calc(100vh - 56px);
    background-color: rgba(128, 128, 128, 0.5);
    /* color */
    display: none;
    /* making it hidden by default */
}

.grayscale {
    filter: grayscale(90%);
    cursor: default;
}

.img-thumbnail {
    padding: .13rem;
}

.btn-custom {
    color: #fff;
    cursor: pointer;
    height: 2.28571em;
    line-height: 2.28571em;
    vertical-align: middle;
    width: auto;
    -webkit-box-pack: center;
    justify-content: center;
    background-color: var(--sp-background-button);
    border-width: 0px;
    border-radius: 3px;
    text-decoration: none;
    transition: background 0.1s ease-out 0s, box-shadow 0.15s cubic-bezier(0.47, 0.03, 0.49, 1.38) 0s;
    padding: 0px 10px;
    outline: none;
    margin: 0px;
}

.btn-custom:hover {
    color: white;
    background-color: var(--sp-background-button-hover);
}

.btn-custom:active,
.btn-custom:visited,
.btn-custom:focus {
    color: white;
    background-color: var(--sp-background-button);
    box-shadow: 0px 3px 12px #1852a044;
}

.btn-custom-second {
    margin: 0 auto;
    font-size: 17px;
    padding: 8px 20px;
    border-radius: 40px;
    color: var(--sp-primary-color);
    border-color: #EFF2F5;
    background-color: #EFF2F5;
    transition: all 0.2s;
    box-shadow: 0px 3px 12px #EFF2F5;
}

.btn-custom-second:hover {
    color: var(--sp-primary-color);
    border-color: #dbdddf;
    background-color: #f3f3f3;
    box-shadow: 0px 1px 22px 1px #dbdddf;
}

.btn-custom-second:active,
.btn-custom-second:visited,
.btn-custom-second:focus {
    color: var(--sp-primary-color);
    border-color: #EFF2F5;
    background-color: #EFF2F5;
    box-shadow: 0px 3px 12px #EFF2F5;
}

.form-control:focus {
    color: #212529;
    background-color: #fff;
    border-color: #b4b1b1;
    /* box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%); */
    outline: none;
}

.form-control:focus-within {
    color: #212529;
    background-color: #fff;
    border-color: #86b7fe;
}

.exam-btn {
    border: 1px solid #c8c8c8;
    border-radius: 2px;
    cursor: pointer;
    display: inline-block;
    font-size: 1rem;
    font-weight: 400;
    padding: 8px 20px;
    text-align: center;
    vertical-align: middle;
    white-space: nowrap;
    margin-bottom: 8px;
    /* -webkit-appearance: none; */
}

.exam-btn-primary-blue {
    background-color: #38aae9;
    border-color: #38aae9;
    color: #ffffff;
}

.exam-btn-primary-save {
    background-color: #0c7cd5;
    border-color: 1px solid #333;
    color: #ffffff;
}

.exam-btn-primary {
    outline: none;
    background-color: #fff;
    color: #252525;
}

.circle-spin-skeleton {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2px;
}

.offcanvas-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1040;
    width: 100vw;
    height: 100vh;
    background-color: #000;
}

.offcanvas-backdrop.show {
    opacity: .5;
}

.long-and-truncated {
    flex: 1;
    min-width: 0;
    /* or some value */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.offcanvas-end {
    width: 300px;
}

hr {
    margin: 0.5rem 0;
}

div.is-disabled {
    pointer-events: none;
    opacity: 0.7;
}

div.is-disabled-hide {
    pointer-events: none;
    opacity: 0;
}

.message-parent {
    position: relative;
    height: 50vh;
}

.message-center {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
}

@media (max-width: 768px) {

    /* Adjust heading sizes for mobile screens */
    h1 {
        font-size: 2rem;
        /* 32px */
    }

    h2 {
        font-size: 1.75rem;
        /* 28px */
    }

    h3 {
        font-size: 1.5rem;
        /* 24px */
    }

    p,
    li {
        font-size: 0.875rem;
        /* 14px */
    }

    .exam-btn-primary-save {
        background-color: var(--sp-exam-button-color);
    }

    .exam-btn {
        padding: 5px 10px;
    }
}
.img_404_body {
    background-color: #f7f7f7;
    position: relative;
    height: calc(100vh - 56px);
}

.img_404 {
    position: relative;
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.img-404-center {
    display: block;
    width: 100%;
    max-height: 400px;
    margin: 0px auto;
    object-fit: contain;
}

.img_set {
    text-align: center;
    background-color: #f7f7f7;
}

@media only screen and (max-width: 768px) {
    .img_404_body {
        height: calc(100vh - 104px);
    }
    .img-404-center {
        display: block;
        width: 100%;
        max-height: 250px;
        margin: 0px auto;
        object-fit: contain;
    }
}
.ad-canlefttops .offcanvas {
    background-color: var(--sp-background-color);
}

.offcanvas-header .logo-with-text {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.offcanvas-header .logo {
    width: 65px;
}

.offcanvas-start {
    width: 300px;
    background-color: var(--sp-background-color);
}

.ad-leftbarcanv {
    padding: 5px;
}

.ad-leftbarcanv .ad-nameicon {
    display: flex;
    align-items: center;
    justify-content: center;
}

.ad-leftbarcanv .ad-menu-icon {
    font-size: 1.2rem;
    color: #a9afbb;
    margin-top: -4px;
}

.ad-leftbarcanv .ad-iconname {
    margin-left: 5px;
}


/*sidebar search */

.ad-canlefttops {
    position: relative;
    margin: 0rem 1.5rem;
}

.ad-canlefttops .ad-sidesearch {
    color: #fff;
    position: relative;
}

.ad-canlefttops .ad-sidesearch input[type=text] {
    border: 0;
    outline: none;
    height: 35px;
    background: #e7f1ff;
    border-radius: 5px;
    display: block;
    margin: 20px 0px;
    width: 100%;
    box-sizing: border-box;
    padding-left: 30px;
    color: #666;
}

.ad-canlefttops .ad-sidesearch .ad-searchBtn {
    width: 30px;
    height: 35px;
    position: absolute;
    background: transparent;
    border: 0;
    font-style: 20px;
    color: #666;
}

.ad-canlefttops .ad-sidedivider {
    width: 100%;
    height: 1px;
    border-radius: 1px;
    background-color: rgb(228, 228, 250);
}


/*side bar middle part */

.ad-leftbarcanv .accordion-button {
    padding: .875rem 1rem;
    color: #4c4f52;
    background-color: var(--sp-background-color);
}

.ad-leftbarcanv .accordion-item a {
    text-decoration: none;
}

.ad-leftbarcanv .accordion-button::after {
    width: 1rem;
    height: 1rem;
    background-size: 1rem;
}

.ad-leftbarcanv .accordion-flush .accordion-item {
    border: none;
}

.ad-leftbarcanv .accordion-button:not(.collapsed) {
    color: #0c63e4;
    background-color: #e7f1ff;
    box-shadow: none;
    border-radius: 5px;
}

.ad-leftbarcanv .accordion-button::after {
    transform: rotate(90deg);
}

.ad-leftbarcanv .accordion-button:not(.collapsed)::after {
    transform: rotate(0deg);
}

.ad-leftbarcanv .accordion-button:focus {
    border-color: #86b7fe;
    outline: 0;
    box-shadow: none;
    border-radius: 5px;
}

.ad-leftbarcanv .accordion-body {
    padding: 0rem 1rem;
    background-color: var(--sp-background-color);
}

.ad-leftbarcanv .list-group {
    padding-top: .5rem;
}

.ad-leftbarcanv .list-group-item {
    color: #212529;
    background-color: var(--sp-background-color);
    border: none;
    border-radius: 5px;
    display: flex;
    align-items: center;
    font-size: .875rem;
    margin: 2px 0px;
}

.ad-leftbarcanv .list-group-item a {
    text-decoration: none;
}

.ad-leftbarcanv .list-group-item-action:focus {
    color: #495057;
    text-decoration: none;
    background-color: #D9D9D9
}

.ad-leftbarcanv .list-group-item-action:active {
    color: #212529;
    background-color: #e9ecef;
}

.ad-leftbarcanv .list-group-item-action:hover {
    color: #495057;
    text-decoration: none;
    background-color: #e9e9e9;
}

.ad-leftbarcanv .accordion-button.collapsed::after.inactive {
    display: none;
}

.inactive:last-child::after {
    display: none;
}


/* sidebar avtar bottom */

.ad-sidecanvbotm {
    background-color: var(--sp-background-color);
}

.ad-sidecanvbotm .ad-sideFooter {
    width: 100%;
    background: var(--background-color);
    border-radius: 5px;
    display: flex;
    align-items: center;
}

.ad-sidecanvbotm .ad-sideFooter .ad-sideFooAvatar {
    display: flex;
}

.ad-sidecanvbotm .ad-sideFooter .ad-sideFooAvatar img {
    max-width: 100%;
    width: 50px !important;
    height: 50px !important;
    height: auto;
    border-radius: 50%;
}

.ad-sidecanvbotm .ad-sideFooter .ad-sideFooInfo {
    color: #4c4f52;
    margin-bottom: -15px;
    margin-left: 10px;
}

.ad-sidecanvbotm .ad-sideFooter .ad-sideFooInfo h5 {
    font-size: 12px;
}

.ad-sidecanvbotm .accordion-button {
    padding: .5rem 1rem;
    background: var(--background-color);
}

.accordion-body .list-group .list-group-item{
    padding: 0.5rem 1rem;
    border: unset;
}
/* .ad-acc-list-he {
    font-size: 100px;
} */
/* Full-Width Skeleton Loader */
.postSk {
    width: 100%; 
    border-radius: 12px; 
    overflow: hidden;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    background-color: #fff;
}

/* Skeleton Image Placeholder with Animation */
.postSkImg {
    background: linear-gradient(
        90deg, 
        #f5f8fa 25%, /* Very light blue-gray */
        #f7f9fb 50%, /* Pale blue with a soft shine */
        #f1f4f6 75%  /* Soft bluish gray */
    );
    background-size: 200% 100%;
    height: 160px;
    border-radius: 10px;
    animation: shimmer 1.5s linear infinite;
    transition: background-color 0.3s ease;
}
/* Skeleton Info Section */
.postSkInfo {
    display: flex;
    align-items: center;
    gap: 1rem; /* Gap between avatar and details */
    margin-top: 1rem; /* Spacing between image and text */
}

/* Skeleton Details with Animated Text */
.postSkDetail {
    display: flex;
    flex-direction: column;
    gap: 0.6rem;
    width: 100%;
}

.postSkText {
    background: linear-gradient(
        90deg, 
        #f5f8fa 25%, /* Very light blue-gray */
        #f7f9fb 50%, /* Pale blue with a soft shine */
        #f1f4f6 75%  /* Soft bluish gray */
    );
    background-size: 200% 100%;
    height: 20px;
    border-radius: 6px; /* Softer rounded corners */
    animation: shimmer 1.5s linear infinite;
}

.postSkText.sm {
    width: 60%; /* Adjusted for better proportion */
    background-color: #e0e0e0; /* Slightly different color */
}

/* Shimmer Animation */
@keyframes shimmer {
    from {
        background-position: -200% 0;
    }
    to {
        background-position: 200% 0;
    }
}

/* Responsive Layout */
@media (max-width: 1024px) {
    .postSk {
        flex: 1 1 calc(50% - 1rem);
    }
}

@media (max-width: 768px) {
    .postSk {
        flex: 1 1 100%; /* Full width on smaller screens */
    }
}

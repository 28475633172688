.internet-error {
    height: 30px;
    background: #ff8100;
    margin-top: 0;
    font-size: 20px;
    z-index: 1000;
    position: fixed;
    display: block;
    justify-content: center;
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    padding: 0px 10px;
}

.internet-error p {
    font-size: 15px;
    line-height: 30px;
    color: #fff;
    margin: 0;
    text-align: center;
}